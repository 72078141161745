// CORE
import React, { useEffect, useState } from 'react';
import {Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { useDispatch, useSelector,connect } from 'react-redux';
import {loadFreshCompuscan} from '../reducers/core/index';
import { Button } from 'reactstrap';
import axios from 'axios';

const EditCommandCell = (props) => {
  console.log('What in here??????=====> ', props);
  if(!props.dataItem.applicationStatus || props.dataItem.applicationStatus === 'Incomplete'){
    return (
      <td>
        <button
          className="k-button k-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          Incomplete
        </button>
      </td>
    );
  }else{ return <></>}
  // else{
  //   return(<td>
  //     <button
  //       className='k-button k-primary'
  //       onClick={() => props.doViewContract()}
  //       >
  //         View Contract
  //     </button>
  //   </td>);
  // }
};
const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://api.willowstaging.tech/api/v1/";// "http://127.0.0.1:8000/api/v1/"; //
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;
const Dashboard = (props) => {
  //window.scrollTo(0,0);
  const [openForm, setOpenForm] = React.useState(false);
  const [appMessage, setAppMessage] = useState();
  //const customerSignIn = useSelector(state => state.applicationReducer.customerSignIn);
  let {account} = props.customerSignIn || [];
  console.log('acccc', account);
  let applicationStatus = '';
  if (account.length) { //.length > 0
    for(var i = 0; i < account.length; i++){
      account[i].loan1 = account[i].loan1 ? account[i].loan1 : account[i].affordability[0].loan1;
      account[i].loanTerms = account[i].loanTerms ? account[i].loanTerms : account[i].affordability[0].loanTerms;
      if(account[i].applicationStatus){
        applicationStatus = account[i].applicationStatus;
      }else{
        applicationStatus = props.selectedApp.applicationStatus || 'Incomplete';
        account[i].applicationStatus = props.selectedApp.applicationStatus || 'Incomplete';
      }
    }
  }
  //const compuscan = useSelector(state => state.applicationReducer.compuscan); 
  console.log('Compuscan?: ', props.compuscan);
  // if(!props.compuscan.customerId){
  //   //get Fresh copy
  //   props.loadFreshCompuscan();
  // }
  const doViewContract = () =>{
    const {custRet, account} = props.customerSignIn
    //alert('I was called');
    props.history.push(`/view-contract?code=${account[0].Id}&user=${custRet[0].RSAIDNumber}`);
  }
  const enterEdit = (item) => {
    const appInfo = {...item, appid:item.Id};
    dispatch({type:'SAVE_APP_DATA', payload:appInfo});
    console.log(appInfo);
    props.history.push('/application');
  };

  if(account.length === 0){
    props.history.push('/application');
  }
  console.log('Is it an array?: ',props.customerSignIn);
  const dispatch = useDispatch();

  const handleRowClick = (event) =>{
    // const appInfo = {...event.dataItem, appid:event.dataItem._id};
    // dispatch({type:'SAVE_APP_DATA', payload:appInfo});
    // console.log(appInfo);
    // props.history.push('/application');
  }
  const goToDashboard = (event) =>{
    event.preventDefault();
    props.history.push('/application');
  }

  const MyEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} doViewContract={doViewContract} />
  );
  const fetchMessage = async ()=>{
    let url = `${API}customers/appMessageSettings`;
    const result = await axios(url);
    let { data } = result;
    console.log('appMessage???:::: ', data);
    
    if(data){ //.length > 0
      setAppMessage(data.data[0].afterCompletionMessage);
    }
  }
  useEffect(() =>{
    if(applicationStatus && applicationStatus === 'Under Review'){
      //get the message
      fetchMessage();
    }
  },[applicationStatus]);
  return (
    <>
    <main id="main">
      <div className="container" data-aos="fade-up">
      <div className='section-title'>
        <h2>Dashboard</h2>
      </div>
      <section id='dashboard' className='dashboard_section'>
        <div className="row mb">
          <div className="col-12">
            <Grid
              data={account}
              onRowClick={handleRowClick}
              >
                {applicationStatus ?
                <GridToolbar>
                  <Button
                    title="New Application"
                    className="k-button k-primary"
                    onClick={goToDashboard}
                  >New Application</Button>
                </GridToolbar> :
                <></>
                }
              <Column field="applicationReferenceNumber" title="Application Reference" width="200px"/>
              <Column field="loan1" title="Loan Amount" width="150px"/>
              <Column field="loanTerms" title="Loan Period" width="150px"/>
              <Column field="applicationStatus" title="Application Status" width="200px"/>
              {(!applicationStatus || (applicationStatus === 'Incomplete')) 
                ? <Column cell={MyEditCommandCell} title='Actions' width="150px" />
                : <></>
              }
              {/* <Column cell={MyEditCommandCell} title='Actions' width="150px" /> */}
            </Grid>
          </div>
        </div>
      </section>
      {applicationStatus && appMessage ? (
      <section className='dashboard_section'>
        <div className='row mb'>
          <div className='col-12' /*style={{border: '1px solid #ccc'}}*/>
            <div style={{fontWeight:'bold', color:'black'}}>
              {<div
                dangerouslySetInnerHTML={{
                  __html: appMessage
                }}></div>}
            </div>
          </div>
        </div>
      </section>
      ):null}
      </div>
      </main>
    </>
  );
};
const mapStateToProps = state => ({
  compuscan: state.applicationReducer.compuscan,
  error: state.applicationReducer.error,
  registerSuc: state.applicationReducer.registerSuc,
  customerSignIn: state.applicationReducer.customerSignIn,
  selectedApp: state.applicationReducer.selectedApp,
});
const mapDispatchToProps =  {
  loadFreshCompuscan
};

const Dash = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default Dash;
