import React, { useEffect }  from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {fetchContract} from '../reducers/core/index';
import { LoadingBox } from './Loading';
import { MessageBox } from './MessageBox';

const ViewContract = (props) =>{
    //window.scrollTo(0,0);
    console.log('Where is: ', props.location.search);
    const arrpara = props.location.search.split('?')[1].split('&');
    const code = arrpara[0].split('=')[1];
    const RSAIDNumber = arrpara[1].split('=')[1];
    console.log('Where is: ', code, RSAIDNumber);
    console.log('And Props=>: ', props);
    useEffect(() =>{
        props.fetchContract({code, RSAIDNumber});
    },[code, RSAIDNumber]); //
    return(
        <main id="main">
            <div className="container" data-oas="fade-up">
                <section id="login" className="login">
                    <div className="section-title pt">
                        <p>CONTRACT DETAILS</p>
                    </div>
                    {props.loading && <LoadingBox />}
                    {(props.viewContract?.message !== '') && <MessageBox>{props.viewContract?.message}</MessageBox>}
                    {(props.viewContract.errors) && <MessageBox variant="danger">{props.error}</MessageBox>}
                    {(props.viewContract?.data[0]?.CustomerContract !== '') &&
                    <div style={{height:800,width:'100%',paddingRight:'5px'}}>
                        <embed src={`data:application/pdf;base64,${props.viewContract?.data[0]?.CustomerContract}`} type='application/pdf' width='100%' height='100%' />
                    </div>
                    }
                </section>
            </div>
        </main>
    );
};
const mapStateToProps = state =>({
    viewContract: state.applicationReducer.viewContract,
});

const mapDispatchToProps = {
    fetchContract,
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewContract);