import State from './initialState';
const initialState = State;

const applicationReducer = (state = initialState, action) =>{
    switch(action.type){
        case 'SAVE_INFO_REQUEST':{
            const item = action.payload;
            console.log('M status: ', item);
            return {
                ...state,
                selectedApp:{...state.selectedApp, ...item},
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        }
        case 'ADD_APP_ID':
            const appid = action.payload;
            const Id = action.payload;
            return{
                ...state,
                selectedApp:{...state.selectedApp, appid, Id},
                upsucc:''
            };
        case 'SAVE_INC_EXP_REQUEST':{
            const affordability = [action.payload];
            return{
                ...state,
                selectedApp:{...state.selectedApp, affordability, ...action.payload},
                upsucc:''
            }
        }
        case 'SAVE_APP_DATA':{
            const obj = {loan1:action.payload.loan1, loanTerms:action.payload.loanTerms, installMent:action.payload.installMent };
            const item = {...state.selectedApp.affordability[0], ...obj};
            let affordability = [item];
            return{
                ...state,
                selectedApp:{...state.selectedApp, ...action.payload, affordability},
                upsucc:''
            };
        }
        case 'SAVE_GENERAL_INFO':{
            const item = action.payload;
            return{
                ...state,
                selectedApp:{...state.selectedApp, ...item},
                //generalInfo:action.payload,
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        }
        case 'SAVE_UPDLOADED_DOCS':{
            const uploadedDocs = action.payload;
            return{
                ...state, 
                selectedApp: {...state.selectedApp, uploadedDocs},
                error:'',
                upsucc:'Documents uploaded successfully',
            };
        }
        case 'SAVE_UPLOADED_DOCS_ERROR':{
            return {
                ...state,
                error:action.payload,
                upsucc:''
            };
        }
        case 'UPDATE_ACCOUNNT':{
            const account = [state.selectedApp];
            return{
                ...state,
                customerSignIn:{...state.customerSignIn, account},
                upsucc:''
            };
        }
        case 'SAVE_LOAN_PARAMS':{
            const item = action.payload;
            let affordability = [...state.selectedApp.affordability]; //, ...item
            affordability[0].loan1 = item[0].loan1;
            affordability[0].loanTerms = item[0].loanTerms;
            affordability[0].installMent = item[0].installMent;
            return {
                ...state,
                selectedApp:{...state.selectedApp, affordability},
                upsucc:''
            };
        }
        case 'SAVE_ADDRESS_INFO':{
            const item = action.payload;
            return{
                ...state,
                //addressInfo:action.payload,
                selectedApp:{...state.selectedApp, ...item},
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        }
        case 'SAVE_EMPLOYER_INFO':{
            const item = action.payload;
            return{
                ...state,
                //employerInfo:action.payload,
                selectedApp:{...state.selectedApp, ...item},
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        }
        case 'SAVE_BANKING_INFO':{
            const item = action.payload;
            return{
                ...state,
                //bankingInfo:action.payload,
                selectedApp:{...state.selectedApp, ...item},
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        }
        case 'SAVE_INCOME_EXPENSES':
            return{
                ...state,
                Income:action.payload.Income,
                Expenses:action.payload.Expenses,
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        case 'SAVE_QUESTIONS_ANSWERS':
            return{
                ...state,
                QuestionsAns:action.payload,
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        case 'SAVE_DOCUMENTS':
            return{
                ...state,
                documents:action.payload,
                error:'',
                //registerSuc:'',
                upsucc:''
            };
        case 'SAVE_INFORMATION_ERROR':
            return{
                ...state,
                error:action.payload,
                //registerSuc:'',
                upsucc:''
            };
        case 'CUSTOMER_REGISTER_SUCCESS':
            return {
                ...state,
                registerSuc:action.payload, //.message
                error:'',
                upsucc:''
            };
        case 'CUSTOMER_REGISTER_ERROR':
            return{
                ...state,
                error:action.payload,
                //registerSuc:'',
                upsucc:''
            };
        case 'OTP_ERROR_SENDING':
            return{
                ...state,
                error:action.payload.otpSent.message,
                registerSuc:action.payload
            };
        case 'OTP_RESEND_SUCCESS1':{
            const otpSent = action.payload;
            return{
                ...state,
                registerSuc:{...state.registerSuc, otpSent}
            };
        }
        case 'OTP_RESEND_ERROR1':{
            const otpSent = action.payload;
            return{
                ...state,
                registerSuc:{...state.registerSuc, otpSent}
            }
        }
        case 'OTP_RESEND_SUCCESS2':{
            const {OTP, message, SMSSentID} = action.payload;
            return{
                ...state,
                customerTemp:{...state.customerTemp, OTP, message, SMSSentID}
            };
        }
        case 'OTP_RESEND_ERROR2':{
            const {OTP, message, SMSSentID} = action.payload;
            return{
                ...state,
                customerTemp:{...state.customerTemp, OTP, message, SMSSentID}
            }
        }
        case 'OTP_VERIFY_SUCCESS':{
            const {OTP, message, SMSSentID} = action.payload;
            return{
                ...state,
                customerTemp:{...state.customerTemp, OTP, message, SMSSentID}
            }
        }
        case 'OTP_VERIFY_ERROR':{
            const {OTP, message, SMSSentID} = action.payload;
            return{
                ...state,
                customerTemp:{...state.customerTemp, OTP, message, SMSSentID}
            }
        }
        case 'SAVE_PROMOCODE':
            return{
                ...state, 
                promoCode:action.payload
            };
        case 'CUSTOMER_LOGIN_SUCCESS':
            return{
                ...state,
                error:'',
                customerSignIn:action.payload,
                //registerSuc:'',
                upsucc:''
            };
        case 'CUSTOMER_TEMP_LOGIN_SUCCESS':
            return{
                ...state,
                customerTemp:action.payload,
            }
        case 'CUSTOMER_LOGIN_ERROR':
            return{
                ...state,
                error:action.payload,
                //registerSuc:'',
                upsucc:''
            };
        case 'SELECTED_APP_USE':
            return{
                ...state,
                selectedApp:action.payload,
                upsucc:''
            };
        case 'LOAD_COMPUSCAN_DATA':
            return{
                ...state,
                error:'',
                registerSuc:'',
                compuscan:action.payload,
                upsucc:''
            };
        case 'LOAD_TRUID_DATA':
            return {
                ...state,
                error:'',
                truID:action.payload,
                upsucc:''
            };
        case 'LOAD_TRUID_DATA_ERROR':
            return{
                ...state,
                error:action.payload,
                upsucc:''
            }
        case 'ACTIVATE_ACCOUNT_REQUEST':
            return{
                ...state,
                loading:true,
                error:'',
                message:'',
                upsucc:''
            };
        case 'ACTIVATE_ACCOUNT_SUCCESS':
            return{
                ...state,
                loading:false,
                error:'',
                message:action.payload,
                upsucc:''
            };
        case 'ACTIVATE_ACCOUNT_ERROR':
            return{
                ...state,
                loading:false,
                message:'',
                error:action.payload,
                upsucc:''
            };
        case 'FETCH_CONTRACT_REQUEST':
            return{
                ...state,
                loading:true,
            }
        case 'FETCH_CONTRACT_SUCCESS':
            return{
                ...state,
                loading:false,
                viewContract:action.payload
            }
        case 'FETCH_CONTRACT_ERROR':
            return{
                ...state,
                loading:false,
                viewContract:action.payload
            }
        case 'CUSTOMER_RESET_PWD_REQUEST':
            return{
                ...state,
                loading:true,
                success: false,
                error:'',
                upsucc:''
            };
        case 'CUSTOMER_RESET_PWD_SUCCESS':
            return{
                ...state,
                loading:false,
                error:'',
                success:true,
                message:action.payload,
                upsucc:''
            };
        case 'CUSTOMER_RESET_PWD_ERROR':
            return{
                ...state,
                loading:false,
                success:false,
                error:action.payload,
                upsucc:''
            };
        case 'CUSTOMER_NEW_PWD':
            return{
                ...state,
                sending:true,
                sucsend:false,
                error:'',
                upsucc:''
            };
        case 'CUSTOMER_NEW_PWD_SUCCESS':
            return{
                ...state,
                sending:false,
                sucsend:true,
                error:'',
                message:action.payload,
                upsucc:''
            };
        case 'CUSTOMER_NEW_PWD_ERROR':
            return{
                ...state,
                sending:false,
                sucsend:false,
                error:action.payload,
                upsucc:''
            };
        case 'LOAD_MIN_SCORE':
            return{
                ...state,
                minimCreditScore: action.payload
            };
        case 'LOAD_PREQU_IDS':
            return{
                ...state,
                preqIDS:action.payload
            }
        case 'LOAD_PREQU_IDS_ERROR':
            return{
                ...state,
                error:action.payload,
            }
        case 'AMORTIZATIONS_REQUEST_SUCCESS':{
            let affordability = [...state.selectedApp.affordability]; //, ...item
            //affordability[0].loan1 = ;
            //affordability[0].loanTerms = item[0].loanTerms;
            affordability[0].installMent = Math.ceil(action.payload.total_receivable/affordability[0].loanTerms);
            return{
                ...state,
                amorTizations:action.payload,
                selectedApp:{...state.selectedApp, affordability},
                amoErr:'',
            };
        }
        case 'AMORTIZATIONS_REQUEST_FAILED':
            return{
                ...state,
                amoErr:action.payload,
            }
        default:
            return state;
    }
};

export {applicationReducer};