// CORE
import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {loginCustomer, registerCustomer, loadCompuscan, loadFreshCompuscan, resendOtp, verifyOtp} from '../reducers/core/index';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { MessageBox } from './MessageBox';
import { Link } from 'react-router-dom';
//import { CheckIDNo } from '../../utils';

const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://api.willowstaging.tech/api/v1/"; // "http://localhost:3000/"; //
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;

const LoginModule = (props) => {
  const recapthaRef = useRef();
  console.log('LO', props)
  const [ customer, setCustomer ] = useState(props.customerSignIn.custRet.firstName);
  const [otp, setOtp] = useState([]);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpVerified1, setOtpVerified1] = useState(false);
  const [reCaptchaValue, setReCaptcha] = useState('');
  const [terms, setTerms] = useState(false);
  const [message, setMessage] = useState('');
console.log('The terms Init:::: ', terms);
function Calculate(Luhn) {
    var sum = 0;
    for (var i=0; i<Luhn.length; i++ ) {
		sum += parseInt(Luhn.substring(i,i+1));
    }
	var delta = new Array (0,1,2,3,4,-4,-3,-2,-1,0);
	for (var i=Luhn.length-1; i>=0; i-=2 ) {		
		var deltaIndex = parseInt(Luhn.substring(i,i+1));
		var deltaValue = delta[deltaIndex];	
		sum += deltaValue;
	}	
	var mod10 = sum % 10;
	mod10 = 10 - mod10;	
	if (mod10===10) {		
		mod10=0;
	}
	return mod10;
 }
function CheckIDNo(){
	var Luhn = document.getElementById('r_idNumber').value; // IDNo;
 	var LuhnLen = Luhn.length;
	var LuhnDigit = parseInt(Luhn.substring(Luhn.length-1,Luhn.length));
	var LuhnLess = Luhn.substring(0,Luhn.length-1);
	var DateOfBirth = Luhn.substring(0,6)
		
	if (Calculate(LuhnLess)===parseInt(LuhnDigit) && (LuhnLen === 13)){
    
    document.getElementById('FirstName').disabled = false;
    document.getElementById('LastName').disabled = false;
    document.getElementById('CellNo').disabled = false;
    document.getElementById('email').disabled = false;
    document.getElementById('r_password').disabled = false;
    //document.getElementById('otp').disabled = false;
    //document.getElementById('otpBtn').disabled = false;
		//return true;

	}	
	else{
		
    document.getElementById('FirstName').disabled = true;
    document.getElementById('LastName').disabled = true;
    document.getElementById('CellNo').disabled = true;
    document.getElementById('email').disabled = true;
    document.getElementById('r_password').disabled = true;
    document.getElementById('otp').disabled = true;
    //document.getElementById('otpBtn').disabled = true;
    alert('Please enter a valid ID number to continue');
	}
 }

  /*
   * Login to dashbaord
   *
   * @method login
   * @param {aEvent}, Button event
   *
   */
  const getOtp = async  (event) => {
    event.preventDefault();
    let r_obj = {
      RSAIDNumber: props.customerTemp.custRet.RSAIDNumber, // document.getElementById('r_idNumber').value,
      firstName: props.customerTemp.custRet.firstName,// document.getElementById('FirstName').value,
      lastName: props.customerTemp.custRet.lastName, // document.getElementById('LastName').value,
      mobileNumber: props.customerTemp.custRet.mobileNumber, // document.getElementById('CellNo').value,
      emailAddress: props.customerTemp.custRet.emailAddress, // document.getElementById('email').value,
      callbackID: props.customerTemp.callbackID
    };
    if(r_obj.RSAIDNumber && r_obj.firstName && r_obj.lastName && r_obj.mobileNumber && r_obj.emailAddress){
        //"{""status"":1,""message"":""Sent"",""details"":""f912a9cd-0a08-493d-0107-012300000034""}"
        var myModal = document.getElementById('exampleModal1');
        myModal.classList.add("show");
        myModal.style.display = 'block';
        props.verifyOtp(r_obj);
        // try{
        //   const {data} = await axios.post(`${API}customers/verify`, r_obj);
        //   if(data.message === 'sent'){
        //     setOtp(data.OTP);
        //   // alert('')
        //   }else{
        //     alert(data.message);
        //   }
        // }catch(e){
        //   alert('There was an error generating the OTP, please try again later ' + e.message);
        // }
    }else{
        alert('Please enter you Id number, first name, last name, mobile number and email address to continue');
    }
  }
  const login = (aEvent) => {
    //setOtp();
    aEvent.preventDefault();
    let RSAIDNumber = document.getElementById('IDNumber').value,
    password = document.getElementById('Password').value;
    //promoCode = document.getElementById('promoCode').value;
    //const captchaToken = recapthaRef.current.getValue();
    //recapthaRef.current.reset();
    console.log('Login:: Token::: ', reCaptchaValue);
    // check creds
    // TODO: Check creds and login then redirect to /dashboard
    // if(reCaptchaValue){
      if (RSAIDNumber !== '' && password !== '') {
        props.loginCustomer({RSAIDNumber, password}, reCaptchaValue, props.promoCode);
      }
    // }else{
    //   alert ("\nYou did not confirm you are not a robot.");
    // }
  };
  const handleOtpChange = (event) =>{
    event.preventDefault();
    //if(parseInt(props.registerSuc.otpSent.OTP) === parseInt(event.target.value)){
    if(props.registerSuc.otpSent.OTP.find(o => parseInt(o) === parseInt(event.target.value))){
      setOtpVerified(true);
      setOtp(event.target.value);
    }else{
      //if(event.target.value.length === 5 && parseInt(props.registerSuc.otpSent.OTP) !== parseInt(event.target.value)){
      if(event.target.value.length === 5 && !props.registerSuc.otpSent.OTP.find(o => parseInt(o) === parseInt(event.target.value))){
        alert('Invalid OTP entered');
      }
    }
  }
  const handleOtpChange1 = (event) =>{
    event.preventDefault();
    //if(parseInt(otp) === parseInt(event.target.value)){
    if(props.customerTemp.OTP.find(o => parseInt(o) === parseInt(event.target.value))){
      setOtpVerified1(true);
      setOtp(event.target.value);
    }else{
      //if(event.target.value.length === 5 && parseInt(otp) !== parseInt(event.target.value)){
      if(event.target.value.length === 5 && !props.customerTemp.OTP.find(o => parseInt(o) === parseInt(event.target.value))){
        alert('Invalid OTP entered');
      }
    }
  }
  /*
  * resending of OTP
  *@method resendOtp
  *@param {aEvent}, Button event
  */
  const resendOtp = (aEvent) => {
    //setOtp([]);
    aEvent.preventDefault();
    if(aEvent.target.value === 'resend2'){
      let r_obj = {
        RSAIDNumber: props.customerTemp.custRet.RSAIDNumber, // document.getElementById('r_idNumber').value,
        firstName: props.customerTemp.custRet.firstName,// document.getElementById('FirstName').value,
        lastName: props.customerTemp.custRet.lastName, // document.getElementById('LastName').value,
        mobileNumber: props.customerTemp.custRet.mobileNumber, // document.getElementById('CellNo').value,
        emailAddress: props.customerTemp.custRet.emailAddress, // document.getElementById('email').value,
        callbackID: props.customerTemp.callbackID
      };
      console.log('test:: ', r_obj, aEvent);
      props.resendOtp(r_obj, aEvent.target.value);
    }else if(aEvent.target.value === 'resend1'){
      let r_obj = {
        RSAIDNumber: props.registerSuc.custRet.RSAIDNumber, // document.getElementById('r_idNumber').value,
        firstName: props.registerSuc.custRet.firstName,// document.getElementById('FirstName').value,
        lastName: props.registerSuc.custRet.lastName, // document.getElementById('LastName').value,
        mobileNumber: props.registerSuc.custRet.mobileNumber, // document.getElementById('CellNo').value,
        emailAddress: props.registerSuc.custRet.emailAddress
      };
      console.log('test:: ', r_obj, aEvent);
      props.resendOtp(r_obj, aEvent.target.value);
    }
  };
  /*
   * register user and redirect to login
   *
   * @method register
   * @param {aEvent}, Button event
   *
   */
  const register = (aEvent) => {
    //setOtp('');
    aEvent.preventDefault();
    let r_obj = {
      RSAIDNumber: document.getElementById('r_idNumber').value,
      firstName: document.getElementById('FirstName').value,
      lastName: document.getElementById('LastName').value,
      mobileNumber: document.getElementById('CellNo').value,
      emailAddress: document.getElementById('email').value,
      password: document.getElementById('r_password').value,
      //termsConditions:terms,
      //role: 'Customer'
    };
    const  confirmEmail = document.getElementById('confirm_email').value;
    //const promoCode = document.getElementById('promoCode').value;
    //if(terms){
      if(r_obj.RSAIDNumber && r_obj.firstName && r_obj.lastName && r_obj.mobileNumber && r_obj.emailAddress && r_obj.password){
        console.log('test:: ', r_obj);
        // TODO: call to reg here
        if(confirmEmail === r_obj.emailAddress){
          props.registerCustomer(r_obj, props.promoCode);
          // var myModal = document.getElementById('exampleModal');
          // myModal.classList.add("show");
          // myModal.style.display = 'block';
        }else{
          alert('Email and confirm email must be same to continue');
        }
      }else{
        alert('All fields are rquired to continue, please enter missing value(s) to continue');
      }
    // }else{
    //   alert('You need to agree to the terms and conditions to continue');
    // }
  };
  const dispatch = useDispatch();
  // const updateOtp = async (RSAIDNumber, SMSSentID, otpVer, arrOtp) =>{
  //   const r_otp = {
  //     RSAIDNumber, 
  //     SMSSentID,
  //     otpVer,
  //     arrOtp
  //   };
  //   const response = axios.post(`${API}customers/updateV`, r_otp);
  //   return response;
  // }
  // const handleOtpCheck = (e) =>{
  //   updateOtp(props.registerSuc.custRet.RSAIDNumber, props.registerSuc.otpSent.SMSSentID, otp, props.registerSuc.otpSent.OTP)
  //   .then(response =>{
  //     if(response.status === 200){
  //       dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload:{custRet:props.registerSuc.custRet, account:props.registerSuc.account}});
  //     }else{
  //       alert('Could not verify your otp, please try again');
  //     }
  //   })
  //   .catch(error => alert('Could not verify your otp, please try again'));
  // }
  // const handleOtpCheck1 = (e) =>{
  //   updateOtp(props.customerTemp.custRet.RSAIDNumber, props.customerTemp.SMSSentID, otp, props.customerTemp.OTP)
  //   .then(response =>{
  //     if(response.status === 200){
  //       dispatch({type:'CUSTOMER_LOGIN_SUCCESS', payload:{custRet:props.customerTemp.custRet, account:props.customerTemp.account}});
  //     }else{
  //       alert('Could not verify your otp, please try again');
  //     }
  //   })
  //   .catch(error => alert('Could not verify your otp, please try again'));
  // }
  const onChange = (value) =>{
    console.log("Captcha value:", value);
  }
  // const openTerms = () =>{
  //   var myModal = document.getElementById('exampleModal2');
  //   myModal.classList.add("show");
  //   myModal.style.display = 'block';
  // }
  // const handleChange = (e) =>{
	// 	setTerms(e.target.checked);
  //   console.log('The value??????? ', e.target.checked);
	// 	var frame = document.getElementById('DeclarationForm');
  //     var checkboxes = frame.contentWindow.document.getElementsByName('IAgree');
  //     for (var i = 0; i < checkboxes.length; i++)
  //     {
  //         checkboxes[i].checked = e.target.checked;
  //     }
	// }
  useEffect(() => {
    window.scrollTo(0,0);
    // setCustomer(props.customerSignIn.custRet.firstName); 
    console.log('props-LOGIN-USEEFFIE:: ', props);
    if(props.customerSignIn.custRet.length > 0) {
      // props.loadFreshCompuscan();//loadCompuscan(props.customerSignIn.custRet.RSAIDNumber);
      if(props.customerSignIn.custRet[0].firstName){
        props.history.push('/dashboard');
      }
    }
  });
  return (
    <main id="main">
    <div className="container" data-aos="fade-up">
    <section id='login' className='login'>
      {/*props.error && <MessageBox variant="danger">{props.error}</MessageBox>*/}
      {/*props.registerSuc && <MessageBox>{props.registerSuc}</MessageBox>*/}
      {/*otp && <MessageBox>Please enter OTP received</MessageBox>*/}
        <div className='section-title pt'>
          <p>Account Profile</p>
        </div>

		    <div className='col-md-12 row'>
    		  <div className='col-md-6'>
      			<div align='center'><h3>Existing Profile</h3></div>
            <form className='login-form'>
              <div className='form-row'>
                {/* <div className='col-md-6 form-group'>
    	            <label className='promoLabel'>Promo Code</label>
                  <input type='text' name='promoCode' className='form-control' id='promoCode' placeholder='Promo Code' maxLength="13" data-rule='minlen:4' data-msg='' />
                </div> */}
    	        </div>
              <div className='form-row'>
                <div className='col-md-6 form-group'>
    	            <label>ID Number</label>
                  <input type='text' name='name' className='form-control' id='IDNumber' placeholder='ID Number' maxLength="13" data-rule='minlen:4' data-msg='Please enter your 13 digit ID number.' />
                  <div className='validate'></div>
                </div>
    	        </div>
    	        <div className='form-row'>
                <div className='col-md-6 form-group'>
  		            <label>Password</label>
                  <input type='password' className='form-control' name='Password' id='Password' placeholder='Your Password' data-rule='minlen:4' data-msg='Please enter your password.' />
                  <div className='validate'></div>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-md-6 form-group'>
                  {/* <ReCAPTCHA
                    ref={recapthaRef}
                    sitekey='6Ldxl8MeAAAAAPS-kwZJ4bxrgbKWw4oT7ydwlSow'
                    onChange={(value) =>setReCaptcha(value)}
                  /> */}
                </div>
              </div>
              <div className='form-row' style={{marginTop:'5px'}}>
                <div className='col-md-6 text-right'>
                  {/* <button type='button' className='get-started-btn' data-toggle="modal" onClick={getOtp}>VERIFY</button> */}
                  <button type='submit' className='get-started-btn' onClick={login}>Login</button>{/*data-target="#exampleModal1"*/}
                  {props.customerTemp.SMSSentID === 'incomplete' ? 
                  <button type='button' className='get-started-btn' data-toggle="modal" onClick={getOtp}>VERIFY</button> : null
                  }
                </div>
              </div>
              <div className='form-row'>
                <div className='col-md-6 form-group'>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-md-6 form-group'>
                {props.customerTemp.SMSSentID === 'incomplete' ? 
                  <MessageBox>Your Cell number was not verified, please verify by clicking the button above</MessageBox>:
                  null
                }
                </div>
              </div>
            </form>
          </div>

          <div className='col-md-6' style={{marginBottom:'50px'}}>
		        <div align='center'><h3>New Profile</h3></div>
            <form className='login-form'>
              <div className='form-row'>
                <div className='col-md-6 form-group'>
		              <label>ID Number</label>
                  <input type='text' name='RSAIDNumber' className='form-control' id='r_idNumber' placeholder='ID Number' onBlur={CheckIDNo} maxLength="13" data-rule='minlen:4' data-msg='Please enter your 13 digit ID number.' />
                  <div className='validate'></div>
                </div>
                <div className='col-md-6 form-group'>
		              <label>First Name</label>
                  <input type='text' name='FirstName' className='form-control' id='FirstName' placeholder='First Name' data-rule='minlen:4' data-msg='Please enter your first name.' />
                  <div className='validate'></div>
                </div>
	            </div>
	            <div className='form-row'>
	 	            <div className='col-md-6 form-group'>
		              <label>Last Name</label>
                  <input type='text' name='LastName' className='form-control' id='LastName' placeholder='Last Name' data-rule='minlen:4' data-msg='Please enter your last name.' />
                  <div className='validate'></div>
                </div>
                <div className='col-md-6 form-group'>
		              <label>Cell No</label>
                  <input type='text' name='CellNo' className='form-control' id='CellNo' placeholder='CellNo' data-rule='minlen:4' data-msg='Please enter your 10 digit cell no.' />
                  <div className='validate'></div>
                </div>
                
	            </div>
	            <div className='form-row'>
                <div className='col-md-6 form-group'>
		              <label>Email</label>
                  <input type='email' className='form-control' name='email' id='email' placeholder='Your Email' data-rule='email' data-msg='Please enter a valid email' />
                  <div className='validate'></div>
                </div>
                <div className='col-md-6 form-group'>
		              <label>Confirm Email</label>
                  <input type='email' className='form-control' name='confirm_email' id='confirm_email' placeholder='Re-enter email' data-rule='minlen:4' data-msg='Please enter your email.' />
                  <div className='validate'></div>
                </div>
                {/* <div className='col-md-6 form-group'>
		              <label>OTP</label>
                  <input type='number' className='form-control' onKeyUp={handleOtpChange} name='otp' id='otp' placeholder='Your OTP' data-rule='otp' data-msg='Please enter the received OTP' />
                  <div className='validate'></div>
                </div> */}
	            </div>
	            <div className='form-row'>
                <div className='col-md-6 form-group'>
		              <label>Password</label>
                  <input type='password' className='form-control' name='Password' id='r_password' placeholder='Your Password' data-rule='minlen:4' data-msg='Please enter your password.' />
                  <div className='validate'></div>
                </div>
              </div>
              
              <div className='form-row'>
                <div className='col-md-6 text-right'>
                  {/* <button type='button' disabled={otpVerified !== false} className='get-started-btn' onClick={getOtp}>Get OTP</button>   <Link to="#" onClick={()=>openTerms()}>Terms</Link> */}
                  {/*!otpVerified ? <button type='button' id='otpBtn' className='get-started-btn' onClick={getOtp}>Get OTP</button> : <></>data-target="#exampleModal"*/}
                  <button type='button' id='otpBtn' className='get-started-btn' style={{marginTop:'35px'}} data-toggle="modal" onClick={register}>Submit</button>
                </div>
              </div>
              {/* <div className='form-row' style={{height:'600px'}}>
                <div className='col-md-12' style={{marginTop:'10px', height:'600px'}}>
                  <form  className="form formSize" name="ApplicationForm" style={{height:'600px'}}>
                    <iframe src={`/declaration.html`} className="disIframe" name="DeclarationForm" id="DeclarationForm" style={{width:'100%', height:'100%'}}></iframe>
                  </form>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-md-9' style={{marginLeft:'30px', marginTop:'30px'}}>
                  <input type="checkbox" className="custom-control-input" name="temrs" id="customCheck1" onChange={(e) =>handleChange(e)} value="1" />
                  <label className="custom-control-label" htmlFor="customCheck1">I agree to the term of use and privacy policy</label>
                </div>
              </div> */}
              {/*{props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
              {props.registerSuc && <MessageBox>{props.registerSuc}</MessageBox>}
              {otp && <MessageBox>Please enter OTP received</MessageBox>}*/}
            </form>
          </div>
        </div>
        <div className='col-md-12 row'>
          <div className='col-md-12'>
          {props.registerSuc.message && <MessageBox>{props.registerSuc.message}</MessageBox>}
          {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
          {/*otp && <MessageBox>Please enter OTP received</MessageBox>*/}
          </div>
        </div>
      {/* </div> */}
    </section>
    </div>
    {/* <div class="modal" id="exampleModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Please enter OTP received below</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() =>{document.getElementById('exampleModal').style.display='none';}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className='col-md-6 form-group'>
                {props.registerSuc.otpSent.OTP.length > 0 &&<MessageBox>Please enter OTP received</MessageBox>}
                {(props.registerSuc.otpSent.message !== 'sent' && props.registerSuc.otpSent.message !== '') && <MessageBox variant="danger">{props.registerSuc.otpSent.message}</MessageBox>}
                
                {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
		            
                <input type='number' className='form-control' onKeyUp={handleOtpChange} name='otp' id='otp' placeholder='Your OTP' data-rule='otp' data-msg='Please enter the received OTP' />
                <div className='validate'></div>
              </div>
          </div>
          <div class="modal-footer">
            {otpVerified ? <button type="button" class="btn btn-primary get-started-btn" data-dismiss="modal" onClick={handleOtpCheck}>Submit</button>: <></>}
            <button type="button" className='btn btn-secondary get-started-btn' value="resend1" onClick={resendOtp}>Resend OTP</button>
            <button type="button" className="btn btn-secondary get-started-btn" data-dismiss="modal" onClick={() =>{document.getElementById('exampleModal').style.display='none';}}>Close</button>
          </div>
        </div>
      </div>
    </div> */}

    {/* <div class="modal" id="exampleModal1" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Please enter OTP received below</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() =>{document.getElementById('exampleModal1').style.display='none';}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className='col-md-6 form-group'>
                {(props.registerSuc.otpSent.OTP.length > 0 || props.customerTemp.OTP.length > 0) &&<MessageBox>Please enter OTP received</MessageBox>}
                {(props.registerSuc.otpSent.message !== 'sent' && props.registerSuc.otpSent.message !== '') && <MessageBox variant="danger">{props.registerSuc.otpSent.message}</MessageBox>}
                {(props.customerTemp.message !== 'sent' && props.customerTemp.message !== '') && <MessageBox variant="danger">{props.customerTemp.message}</MessageBox>}
                
                <input type='number' className='form-control' onKeyUp={handleOtpChange1} name='otp' id='otp1' placeholder='Your OTP' data-rule='otp' data-msg='Please enter the received OTP' />
                <div className='validate'></div>
              </div>
          </div>
          <div class="modal-footer">
            {otpVerified1 ? <button type="button" class="btn btn-primary get-started-btn" data-dismiss="modal" onClick={handleOtpCheck1}>Submit</button>: <></>}
            <button type="button" className='btn btn-secondary get-started-btn' value="resend2" onClick={resendOtp}>Resend OTP</button>
            <button type="button" class="btn btn-secondary get-started-btn" data-dismiss="modal" onClick={() =>{document.getElementById('exampleModal1').style.display='none';}}>Close</button>
          </div>
        </div>
      </div>
    </div> */}
    
    <div class="modal" id="exampleModal2" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">TERMS AND CONDITION</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() =>{document.getElementById('exampleModal2').style.display='none';}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div className='col-md-6 form-group'>
                <p>
                On the Insert tab, the galleries include items that are designed to coordinate with the overall look of your document. 
                You can use these galleries to insert tables, headers, footers, lists, cover pages, and other document building blocks. 
                When you create pictures, charts, or diagrams, they also coordinate with your current document look. 
                You can easily change the formatting of selected text in the document text by choosing a look for the selected text from the Quick Styles gallery on the Home tab. 
                You can also format text directly by using the other controls on the Home tab. 
                Most controls offer a choice of using the look from the current theme or using a format that you specify directly. 
                To change the overall look of your document, choose new Theme elements on the Page Layout tab. 
                To change the looks available in the Quick Style gallery, use the Change Current Quick Style Set command. 
                Both the Themes gallery and the Quick Styles gallery provide reset commands so that you can always restore the look of your document to the original contained in your current template. 
                On the Insert tab, the galleries include items that are designed to coordinate with the overall look of your document.
                </p>
              </div>
          </div>
          <div class="modal-footer">
            {/* <button type="button" class="btn btn-primary get-started-btn" data-dismiss="modal" onClick={handleOtpCheck1}>Accept</button>: <></>
            <button type="button" className='btn btn-secondary get-started-btn' value="resend2" onClick={resendOtp}>Reject</button> */}
            <button type="button" class="btn btn-secondary get-started-btn" data-dismiss="modal" onClick={() =>{document.getElementById('exampleModal2').style.display='none';}}>Close</button>
          </div>
        </div>
      </div>
    </div>
    </main>
  );
};

const mapStateToProps = state => ({
  generalInfo: state.applicationReducer.generalInfo,
  addressInfo: state.applicationReducer.addressInfo,
  employerInfo: state.applicationReducer.employerInfo,
  bankingInfo: state.applicationReducer.bankingInfo,
  Income: state.applicationReducer.Income,
  Expenses: state.applicationReducer.Expenses,
  QuestionsAns: state.applicationReducer.QuestionsAns,
  documents: state.applicationReducer.documents,
  error: state.applicationReducer.error,
  registerSuc: state.applicationReducer.registerSuc,
  customerSignIn: state.applicationReducer.customerSignIn,
  customerTemp: state.applicationReducer.customerTemp,
  promoCode: state.applicationReducer.promoCode,
});
const mapDispatchToProps =  {
  loginCustomer,
  registerCustomer,
  resendOtp,
  verifyOtp,
  loadCompuscan,
  loadFreshCompuscan
};

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginModule);
export default Login;
