
// init InitialState
var InitialState = {
  language: 'en',
  isLoadingComplete: false,
  loader: false,
  notification: {
    type: '',
    text: ''
  },
  customerSignIn:{custRet:[], account:[]},
  customerTemp:{SMSSentID:'', OTP:[], message:''},
  selectedApp:{affordability:[{loan1:0,loanTerms:0, installMent:0}]},
  generalInfo:{},
  addressInfo:{},
  employerInfo:{},
  bankingInfo:{},
  Income:[],
  Expenses:[],
  questionsAns:{},
  documents:{},
  error:'',
  upsucc:'',
  registerSuc:{message:'',account:[],custRet: {}, otpSent:{OTP:[], SMSSentID:'', message:''}}, //registerSuc:''
  viewContract:{message:'',data:[], error:''},
  compuscan:{},
  loanParams:{},
  truID:'',
  minimCreditScore:0,
  preqIDS:[],
  promoCode:'',
  amorTizations:{},
};
// export InitialState
export default InitialState
