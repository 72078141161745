import React, { useState, useEffect } from 'react';
import { MessageBox } from './MessageBox';
import ProgressBr from './Progress';
import axios from 'axios';
import { useSelector } from 'react-redux';
import LoanSummary from './LoanSummary';
import { LoadingBox } from './Loading';

const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "http://localhost:3000/"; //"https://api.amabuzz.com/"; // 
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;

const Questions = ({ nextStep, prevStep, handleChange, values, error, RSAIDNumber }) => {
  //window.scrollTo(0,0);
  const [errContinue, setErrCont] = useState('');
  const [canContinue, setCanContinue] = useState(true);
  const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
  const { affordability, firstName, lastName } = selectedApp;
  const [questions, setQuestions] = useState([]);
  const [obj, setObj] = useState({
    applicationReference: '1234545',
    telephoneNumbers: false,
    employers: false,
    addresses: false,
    accounts: false
  });
  const [loading, setLoading] = useState(false);
  const Continue = e => {
    // check all questions is correct ?
    // validate ?
    console.log('obj', obj);
    e.preventDefault();
    ///return;
    // if (obj.employers && obj.addresses && obj.accounts) {
      nextStep(obj);
    // } else {
    //   setCanContinue(false);
    //   setErrCont('Some of the answers you provided are wrong, you do not qualify');
    // }
  }
  const Previous = e => {
    e.preventDefault();
    prevStep();
  }
  let bfoundAdmin = false, bfoundFraudster = false, bfoundDebtReview = false;
  //const compuscan = useSelector(state => state.applicationReducer.compuscan);
  const minimCreditScore = useSelector(state => state.applicationReducer.minimCreditScore);
  const preqIDS = useSelector(state => state.applicationReducer.preqIDS);
  /*
   * Get the list of questions
   *
   * @method fetchQuestions
   */
  const fetchQuestions = async () => {
    let url = `${API}questions/${RSAIDNumber}`;
    //let url = `${API}questions/7701026954269`;
    const result = await axios.get(url);
    let { data } = result;
    console.log('Quuid Data::: ', data);
    // test
    data[0].accounts.splice(Math.floor(Math.random() * data[0].telephoneNumbers.length), 0, data[1].accounts[0]);
    data[0].addresses.splice(Math.floor(Math.random() * data[0].telephoneNumbers.length), 0, data[1].addresses[0]);
    data[0].employers.splice(Math.floor(Math.random() * data[0].telephoneNumbers.length), 0, data[1].employers[0]);
    //data[0].telephoneNumbers.splice(Math.floor(Math.random() * data[0].telephoneNumbers.length), 0, data[1].telephoneNumbers[0]);
    data[0].telephoneNumbers.push(data[1].telephoneNumbers[0]);
    //None answer 
    data[0].accounts.push(data[1].accounts[1]);
    data[0].addresses.push(data[1].addresses[1]);
    data[0].employers.push(data[1].employers[1]);
    setQuestions(data);
  };
  const fetchCompuscan = async () => {
    setLoading(true);
    const COMPAPI = "http://localhost:3000/"; //"https://amabuzz.ngrok.io/"; // Identity_number:'7701026954269', Forename:'Laurent', Surname: 'Kayembe' /getCompFromCCS
    const result = await axios.post(`${COMPAPI}xds/ConnectConsumerMatchCreditAssesment`, { Identity_number: RSAIDNumber, Forename: firstName, Surname: lastName });
    if (result.status === 200) {
      //let reason = result.data.CODIX.PRODUCTS.product.reasons.reason;
      //Call EP to save the pdf report
      const ret = await axios.post(`${COMPAPI}xds/ConnectGetResultBinary2`,{Identity_number: RSAIDNumber, Forename: firstName, Surname: lastName});
      const { data } = result;
      // if(data.Consumer.ConsumerDebtReviewStatus || data.Consumer.ConsumerScoring.Exception_Code === 'DEBT REVIEW LISTED AGAINST CONSUMER'){
      //   bfoundDebtReview = true;
      // }
      // if(reason.length){
      //     fetchQuestions();
      //     for(var i = 0; i < reason.length; i++){
      //         let str = reason[i]._;
      //         if(str.indexOf('Customer is under administration') >= 0){
      //         bfoundAdmin = true;
      //         }else if(str.indexOf('Customer is listed as under Debt Counselling') >= 0){
      //         bfoundDebtReview = true;
      //         }else if(str.indexOf('Listed with SAFPS as a perpetrator (Fraud)') >= 0){
      //         bfoundFraudster = true;
      //         }
      //     }
      if (preqIDS.find(x => x.RSAIDNumber === RSAIDNumber) && !bfoundAdmin && !bfoundDebtReview && !bfoundFraudster) {
        setCanContinue(true);
        setErrCont('');
      }
      else if (data.Consumer.ConsumerScoring.FinalScore > minimCreditScore && !bfoundAdmin && !bfoundDebtReview && !bfoundFraudster) {
        setCanContinue(true);
        setErrCont('');
      } else {
        setCanContinue(false);
        setErrCont('You do not qualify, please try again in the future');
      }
      console.log('Can I continue?: ', canContinue);
      //}
    }
    setLoading(false);
  }
  const onChangeHandler = (aEvent) => {
    let name = aEvent.target.name;
    let value = aEvent.target.value;
    console.log('questions:: ', questions);
    console.log('name', name);
    console.log('value', value)
    Object.assign(obj, {
      [name]: questions[1][name].findIndex(a => a === value) >= 0 ? true : false
    });
    setObj(obj);
  }

  useEffect(() => {
    // fetchCompuscan();
    // fetchQuestions();
    // let reason = compuscan.CODIX.PRODUCTS.product.reasons.reason;
    // if(reason.length){
    //     for(var i = 0; i < reason.length; i++){
    //         let str = reason[i]._;
    //         if(str.indexOf('Customer is under administration') >= 0){
    //         bfoundAdmin = true;
    //         }else if(str.indexOf('Customer is listed as under Debt Counselling') >= 0){
    //         bfoundDebtReview = true;
    //         }else if(str.indexOf('Listed with SAFPS as a perpetrator (Fraud)') >= 0){
    //         bfoundFraudster = true;
    //         }
    //     }
    //     if(preqIDS.find(x => x.RSAIDNumber === RSAIDNumber) && !bfoundAdmin && !bfoundDebtReview && !bfoundFraudster){
    //         setCanContinue(true);
    //         setErrCont('');
    //     }
    //     else if(compuscan.EnqCC_CustomSCORE.ROW.SCORE > minimCreditScore && !bfoundAdmin && !bfoundDebtReview && !bfoundFraudster){
    //         setCanContinue(true);
    //         setErrCont('');
    //     }else{
    //         setCanContinue(false);
    //         setErrCont('You do not qualify, please try again in the future');
    //     }
    //     console.log('Can I continue?: ', canContinue);
    // }
  }, [/*compuscan.CODIX.PRODUCTS.product.reasons.reason.length, compuscan.EnqCC_CustomSCORE.ROW.SCORE*/]);
  return (
    <>
      <main id="main">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Application</h2>
          </div>
          <section id="application" className="application_section">
            <LoanSummary affordability={affordability} />
            <ProgressBr n={5} />
            {error && <MessageBox variant="danger">{error}</MessageBox>}
            {errContinue && <MessageBox variant="danger">{errContinue}</MessageBox>}
            {loading && <LoadingBox size={'30px'} color={'red'} />}
            {canContinue ?
              (
              <div className="row mb">
                <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                  {/* <h2 className="mb-15">Personal Information</h2> */}
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">
                        <h5 className="text-grey">Please Answer the following questions</h5>
                      </div>
                      <div className="col-12">
                        <div style={{}}>
                          <p>Which of these employers did you work for?</p>
                          <ol style={{ width: '100%' }}>
                            {questions.length > 0 ? questions[0].employers.map((aItem, aIndex) => {
                              return (
                                <li style={{ float: 'left', textAlign: 'left', width: '100%' }} key={aIndex}>
                                  <div>
                                    <input style={{ width: '10%' }} type="radio" name={'employers'} value={aItem} onChange={onChangeHandler} />
                                    <span>{aItem.toUpperCase()}</span>
                                  </div>
                                </li>
                              )
                            }) : null}
                          </ol>
                          <br />
                          <p>Which of the following addresses is familiar to you?</p>
                          <ol style={{ width: '100%' }}>
                            {questions.length > 0 ? questions[0].addresses.map((aItem, aIndex) => {
                              return (
                                <li style={{ float: 'left', textAlign: 'left', width: '100%' }} key={aIndex}>
                                  <div>
                                    <input style={{ width: '10%' }} type="radio" name={'addresses'} value={aItem} onChange={onChangeHandler} />
                                    <span>{aItem.toUpperCase()}</span>
                                  </div>
                                </li>
                              )
                            }) : null}
                          </ol>
                          <br />
                          <p>Which of the following accounts do you currently have open?</p>
                          <ol style={{ width: '100%' }}>
                            {questions.length > 0 ? questions[0].accounts.map((aItem, aIndex) => {
                              return (
                                <li style={{ float: 'left', textAlign: 'left', width: '100%' }} key={aIndex}>
                                  <div>
                                    <input style={{ width: '10%' }} type="radio" name={'accounts'} value={aItem} onChange={onChangeHandler} />
                                    <span>{aItem.toUpperCase()}</span>
                                  </div>
                                </li>
                              )
                            }) : null}
                          </ol>
                          <br />
                          <p>Which of the following numbers is familiar to you?</p>
                          <ol style={{ width: '100%' }}>
                            {questions.length > 0 ? questions[0].telephoneNumbers.map((aItem, aIndex) => {
                              return (
                                <li style={{ float: 'left', textAlign: 'left', width: '100%' }} key={aIndex}>
                                  <div>
                                    <input style={{ width: '10%' }} type="radio" name={'telephoneNumbers'} value={aItem} onChange={onChangeHandler} />
                                    <span>{aItem.toUpperCase()}</span>
                                  </div>
                                </li>
                              )
                            }) : null}
                          </ol>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              ) : null}
            {canContinue ?
              <div className="row mb">
                <div className="col-12">
                  <div style={{ float: 'right' }}>
                    <div style={{ float: 'right', marginTop: '10px', marginBottom: '30px' }}>
                      <button type="button" className="get-started-btn" onClick={Previous}><i className="fa fa-chevron-left"></i> Back</button>
                      {canContinue ?
                        <button type="button" className="get-started-btn" onClick={Continue}>Next</button> :
                        <button type="button" className="get-started-btn" disabled onClick={Continue}>Next</button>
                      }
                    </div>
                  </div>
                </div>
              </div> : null}
          </section>
        </div>
      </main>
    </>
  );
};

export default Questions;
