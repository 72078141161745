import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { MessageBox } from './MessageBox';
import ProgressBr from './Progress';
import LoanSummary from './LoanSummary';
import { loadFreshCompuscan, fetchMinCredit, fetchPrequalifiedIDS, fetchAmortizations } from '../reducers/core';
import axios from 'axios';
import { LoadingBox } from './Loading';
const { REACT_APP_NGROK_URL, REACT_APP_LIVE } = process.env;
const API_ADDRESS = `http://${REACT_APP_NGROK_URL}/`;
const API_LOCAL = "https://api.willowstaging.tech/api/v1/"; //"http://localhost:3000/"; //  
const API = REACT_APP_LIVE === 'live' ? API_ADDRESS : API_LOCAL;

const branchCode = ['198765', '470010',  '632005', '410506','051001','250655']; //'280372', 'BARODACODE', '482273', '281267',   =>,'250655'
const BankingInfo = (props) => {//,{nextStep, prevStep, error, values}
    //window.scrollTo(0,0);
    const [bankName, setbankName] = useState(props.values.bankName);
    const [bankBranch, setbankBranch] = useState(props.values.bankBranch || '');
    const [bankAccountNumber, setbankAccountNumber] = useState(props.values.bankAccountNumber || '');
    const [bankAccountType, setbankAccountType] = useState(props.values.bankAccountType);
    const [errContinue, setErrCont] = useState('');
    const [canContinue, setCanContinue] = useState(false);
    const [loading, setLoading] = useState(false);
    //const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const {selectedApp} = props;
    const {affordability} = selectedApp;
    const Continue = e => {
        e.preventDefault();
        // checkAcc().then(retu =>{
        //     if(retu.ret === true){
        //         setErrCont('');
        //         setLoading(false);
               if(handleValidation()){
                    props.nextStep({bankName, bankBranch, bankAccountNumber, bankAccountType});
                } 
        //     }else{
        //         setErrCont(retu.data.message);   
        //         setLoading(false);
        //     }
        // }).catch(error =>{
        //     setErrCont(error);   
        //     setLoading(false);
        // })
        
    }
    const Previous = e => {
        e.preventDefault();
        props.prevStep();
    }
    const handleValidation = () =>{
        let valid = true;
        if(document.getElementById('bankName').value === "")
        {
            document.getElementById('bankName').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('bankName').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('bankAccountNumber').value === "")
        {
            document.getElementById('bankAccountNumber').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('bankAccountNumber').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('bankAccountType').value === "")
        {
            document.getElementById('bankAccountType').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('bankAccountType').style.backgroundColor = '#ffffff';
            //valid = true;
        }
        if(document.getElementById('bankBranch').value === "")
        {
            document.getElementById('bankBranch').style.backgroundColor = '#ffebe6';
            valid = false;
        }else{
            document.getElementById('bankBranch').style.backgroundColor = '#ffffff';
            //valid = true;
        }

        return valid;
    }
    const handleBankChange = event =>{
        setbankName(event.target.value);
        if(event.target.value === 'C0E405A4-DF1F-4EFB-A07D-016C59194BFA'){
            document.getElementById('bankBranch').value = branchCode[0];
            setbankBranch(branchCode[0]);
        }else if(event.target.value === 'E747A210-F00A-4E27-975E-2CF6EFDEB68D'){
            document.getElementById('bankBranch').value = branchCode[1];
            setbankBranch(branchCode[1]);
        }else if(event.target.value === 'F08AFE75-39C1-4590-9389-CBCEC8339CEE'){
            document.getElementById('bankBranch').value = branchCode[2];
            setbankBranch(branchCode[2]);
        }else if(event.target.value === 'B57737C5D-5114-48FF-857A-E51D40079429'){
            document.getElementById('bankBranch').value = branchCode[3];
            setbankBranch(branchCode[3]);
        }else if(event.target.value === '168D23D9-CB7A-47FB-8308-EF85DB4C20DD'){
            document.getElementById('bankBranch').value = branchCode[4];
            setbankBranch(branchCode[4]);
        }else if(event.target.value === '0CF9A57A-37BD-4943-94C7-FC7D3955AC1A'){
            document.getElementById('bankBranch').value = branchCode[5];
            setbankBranch(branchCode[5]);
        }
    }
    const checkAcc = async () =>{
        if(bankAccountNumber !== '' && bankBranch !== ''){
            setLoading(true);
            const url = `${API}customers/validateAccount`;
            const params = {account_type:bankAccountType, bank_acc_no:bankAccountNumber, bank_branch_cd:branchCode };
            const account_type = bankAccountType === '1' ? 'Savings' : 'Current';
            const response = await axios.post(url, {account_type:account_type, bank_acc_no:bankAccountNumber.trim(), bank_branch_cd:bankBranch.trim() });
            if(response.status === 200){
                //setLoading(false);
                return {data:response.data, ret:true};
                // setCanContinue(true);
                // setErrCont('');
                // setLoading(false);
            }else if(response.status === 201){
                return {data:response.data, ret:false};
                // setCanContinue(false);
                // setErrCont(response.data.message); 
                // setLoading(false);
            }
        }
    }
    useEffect(()=>{
        console.log('props-Bankinginfo-Useeefei');
        if(selectedApp && selectedApp.RSAIDNumber && selectedApp.firstName && selectedApp.lastName){
            console.log("How about here");
            //props.loadFreshCompuscan(selectedApp.RSAIDNumber, selectedApp.firstName, selectedApp.lastName); loadFreshCompuscan, 
            //props.fetchMinCredit();
            //props.fetchPrequalifiedIDS();
            let pf = {
                freq: selectedApp.salaryFrequency,
                name: selectedApp.salaryDay
            };
            let loanAmount = selectedApp.affordability[0].loan1;
            let loanTerms = selectedApp.affordability[0].loanTerms;
            //props.fetchAmortizations({loanAmount, loanTerms, pf});
        }
    },[fetchMinCredit, fetchPrequalifiedIDS, fetchAmortizations, selectedApp.RSAIDNumber, selectedApp.firstName, selectedApp.lastName])
    return(
        <>
        <main id="main">
        <div className="container" data-aos="fade-up">
        <div className="section-title">
            <h2>Application</h2>
        </div>
        <section id="application" className="application_section">
            <LoanSummary affordability={affordability} />
            <ProgressBr n ={3}/>
            {props.error && <MessageBox variant="danger">{props.error}</MessageBox>}
            {errContinue && <MessageBox variant="danger">{errContinue}</MessageBox>}
            {loading && <LoadingBox size={'30px'} color={'red'} /> }
            <div className="row mb">
                <div className="col-12" data-oas="fade-left" data-oas-delay="100">
                    {/* <h2 className="mb-15">Personal Information</h2> */}
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title mb">
                                <h5 className="text-grey">Banking Information</h5>
                            </div>
                            <div className="row mb">
                                <div className="col-md-4">
                                    <label htmlFor="bankName" className="form-label"><small style={{ color: 'red' }}>*</small> Bank Name</label>
                                    <div className="input-group">
                                        <select name="bankName" id="bankName" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('bankName').style.backgroundColor='#ffffff'}} onChange={handleBankChange} value={bankName}>
                                            <option selected>select...</option>
                                            <option value="C0E405A4-DF1F-4EFB-A07D-016C59194BFA">
                                            Nedbank
                                            </option>
                                            <option value="E747A210-F00A-4E27-975E-2CF6EFDEB68D">
                                            Capitec Bank
                                            </option>
                                            <option value="F08AFE75-39C1-4590-9389-CBCEC8339CEE">
                                            ABSA
                                            </option>
                                            <option value="B57737C5D-5114-48FF-857A-E51D40079429">
                                            Bank of Athens
                                            </option>
                                            <option value="168D23D9-CB7A-47FB-8308-EF85DB4C20DD">
                                            Standard Bank South Africa
                                            </option>
                                            <option value="0CF9A57A-37BD-4943-94C7-FC7D3955AC1A">
                                            FNB South Africa
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="bankAccountType" className="form-label">Account Type</label>
                                    <div className="input-group">
                                        <select name="bankAccountType" id="bankAccountType" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('bankAccountType').style.backgroundColor='#ffffff'}} onChange={e => setbankAccountType(e.target.value)} value={bankAccountType}>
                                            <option selected>select...</option>
                                            <option value="1">
                                            Savings
                                            </option>
                                            <option value="2">
                                            Current
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="bankAccountNumber" className="form-label"><small style={{ color: 'red' }}>*</small> Account Number</label>
                                    <div className="input-group">
                                        <input name="bankAccountNumber" id="bankAccountNumber" type="text" className="form-control"  onClick={(e) => [document.getElementById('bankAccountNumber').style.backgroundColor='#ffffff',setErrCont('')]} onChange={e => setbankAccountNumber(e.target.value)} value={bankAccountNumber}/>
                                    </div>
                                </div>

                                {/* <div className="col-md-4"> onBlur={checkAcc}
                                    <label htmlFor="bankAccountType" className="form-label">Account Type</label>
                                    <div className="input-group">
                                        <select name="bankAccountType" id="bankAccountType" className="form-select" aria-label="Bank Name" onClick={(e) => {document.getElementById('bankAccountType').style.backgroundColor='#ffffff'}} onChange={e => setbankAccountType(e.target.value)} value={bankAccountType}>
                                            <option selected>select...</option>
                                            <option value="1">
                                            Savings
                                            </option>
                                            <option value="2">
                                            Current
                                            </option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mb">
                                <div className="col-md-4">
                                    <label htmlFor="bankBranch" className="form-label"><small style={{ color: 'red' }}>*</small> Branch Code</label>
                                    <div className="input-group">
                                        <input name="bankBranch" id="bankBranch" type="text" className="form-control" onClick={(e) => {document.getElementById('bankBranch').style.backgroundColor='#ffffff'}} disabled value={bankBranch} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb">
                    <div className="col-12">
                        <div style={{float:'right'}}>
                            <div style={{float:'right',marginTop:'3px',marginBottom:'30px'}}>
                                <button type="button" className="get-started-btn" onClick={Previous}><i className="fa fa-chevron-left"></i> Back</button>
                                {/* {canContinue ?  */}
                                <button type="button" className="get-started-btn" onClick={Continue}>Next</button> 
                                {/* <button type="button" className="get-started-btn" disabled onClick={Continue}>Next</button> */}
                              {/* } */}
                                {/* <button type="button" className="get-started-btn" onClick={Continue}>Next</button> */}
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        </div>
        </main>
        </>
    );
};
const mapStateToProps = state =>({
    selectedApp: state.applicationReducer.selectedApp,
});
const mapDispatchToProps ={
    loadFreshCompuscan,
    fetchMinCredit,
    fetchPrequalifiedIDS,
    fetchAmortizations,
}
export default connect(mapStateToProps, mapDispatchToProps)(BankingInfo);
