import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoanSummary from './LoanSummary';
import ProgressBr from './Progress';
import TruIDIframe from './TruIfram';
//import socketio from "socket.io-client";
import useMutationObservable from './useMutationObservable';

const TruIDBankStatement = (props)=>{
    //window.scrollTo(0,0);
    const Continue = e => {
        e.preventDefault();
        props.nextStep();
    }
    const [shown, setShown] = useState(false);
    //const socket =socketio('https://amabuzzsocket.herokuapp.com');
    const selectedApp = useSelector(state => state.applicationReducer.selectedApp);
    const truID = useSelector(state => state.applicationReducer.truID);
    const {affordability} = selectedApp;
    const handleWatever = (data) => {
        //Three statuses received:
        //Code: 201, message: 'Invite accepted'
        //code: 1110, message: 'Authorization required'
        //code: 2000, message: 'Collection successful.'
        console.log('Emitted data: ', data);
        if(data.code === 2000){ 
            setShown(true);
        }
    }
    const onMutation = useCallback(
        (mutationList) =>{
            console.log('Mutate=> ', mutationList);
        },[handleWatever]
    )
    
    //useMutationObservable(my_auto, onMutation); https://api.truidconnect.io/consumer-api//collections/a5vl6etnvin5cid0jbt5dj07d/milestones
    const onLoad = () =>{
        const my_auto = document.querySelector('.my-auto');
        console.log('onLoad??? ', my_auto);
    }
    useEffect(() =>{
        const my_auto = document.querySelector('.my-auto');
        console.log('Any My-auto??? ', my_auto);
        
        //socket.on("whatever", handleWatever);  
    
    },[]); //socket,handleWatever
    return(
        <main id="main">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Application</h2>
                </div>
                <section id="application" className="application_section">
                    <LoanSummary affordability={affordability} />
                    <ProgressBr n ={7}/>
                    <div className="row mb">
                        <div className="col-md-12" data-oas="fade-left" data-oas-delay="100">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title mb">
                                        <h5 className="text-grey">Download Bank Statements</h5>
                                    </div>
                                    <TruIDIframe src={truID} width={100}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb">
                    <div className="col-12">
                        <div style={{float:'right'}}>
                            <div style={{float:'right',marginTop:'10px',marginBottom:'30px'}}>
                                {/* <button type="button" className="get-started-btn" onClick={Continue}>Next</button> */}
                                {shown ? 
                                <button type="button" className="get-started-btn" onClick={Continue}>Next</button> :
                                <button type="button" className="get-started-btn" disabled onClick={Continue}>Next</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        </main>
    );
};

export default TruIDBankStatement
